var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work_library materials common_page" },
    [
      _c(
        "van-tabs",
        {
          attrs: { background: "#161626", "line-width": 64, "line-height": 4 },
          on: { change: _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("van-tab", {
            attrs: {
              title: "日常发布",
              name: "1",
              "title-class": "vertical_divider"
            }
          }),
          _c("van-tab", {
            attrs: { title: "公共作品", name: "2", disabled: _vm.listLoading }
          })
        ],
        1
      ),
      _c("div", { staticClass: "filter" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "f12" }, [
            _vm._v("共" + _vm._s(_vm.dataTotal) + "个素材")
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "right",
            on: {
              click: function($event) {
                _vm.showFilter = !_vm.showFilter
              }
            }
          },
          [
            _c("span", [_vm._v("筛选")]),
            _c("van-icon", { attrs: { name: "filter-o" } })
          ],
          1
        )
      ]),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.showFilter, "z-index": 120 },
          on: {
            click: function($event) {
              _vm.showFilter = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      name: "picker",
                      value: _vm.cityValue,
                      label: "城市：",
                      placeholder: "请选择",
                      "is-link": "",
                      "input-align": "right"
                    },
                    on: {
                      click: function($event) {
                        _vm.cityShow = true
                      }
                    }
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: { position: "bottom" },
                      model: {
                        value: _vm.cityShow,
                        callback: function($$v) {
                          _vm.cityShow = $$v
                        },
                        expression: "cityShow"
                      }
                    },
                    [
                      _c("van-picker", {
                        attrs: { "show-toolbar": "", columns: _vm.cityList },
                        on: {
                          confirm: _vm.onCityConfirm,
                          cancel: function($event) {
                            _vm.cityShow = false
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter_btn" },
                    [
                      _c(
                        "van-button",
                        {
                          staticClass: "reset_btn mr20",
                          attrs: { size: "small", block: "" },
                          on: { click: _vm.resetFilter }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { size: "small", type: "primary", block: "" },
                          on: { click: _vm.filters }
                        },
                        [_vm._v("筛 选")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "contentWarp",
          staticClass: "list-warp mobile_warp",
          attrs: {
            "element-loading-background": "rgba(0, 0, 0, 0.1)",
            "element-loading-text": "素材加载中..."
          }
        },
        [
          !_vm.listData.length && !_vm.listLoading
            ? _c("noResult", {
                staticStyle: { height: "55vh" },
                attrs: {
                  iconUrl: "icon-meiyousucai",
                  iconSty: "font-size:80px",
                  textSty: "font-size:18px",
                  noResultText: "暂无相关素材"
                }
              })
            : [
                _c(
                  "div",
                  { staticClass: "material_list" },
                  _vm._l(_vm.listData, function(item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "material_item" },
                      [
                        _c("div", { staticClass: "item_top" }, [
                          _c(
                            "div",
                            {
                              staticClass: "videoSize",
                              on: {
                                click: function($event) {
                                  return _vm.videoClick(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.showImg,
                                      expression: "item.showImg"
                                    }
                                  ],
                                  staticClass: "play_video"
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-bofang1"
                                  })
                                ]
                              ),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.showImg,
                                    expression: "item.showImg"
                                  }
                                ],
                                attrs: { src: item.cover, alt: "" }
                              }),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.showImg,
                                    expression: "!item.showImg"
                                  }
                                ],
                                style: item.showImg ? "width:0;height:0" : "",
                                attrs: { id: "video" + item.id }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btns",
                              style:
                                item.progress != 0 &&
                                item.progress <= 0.99 &&
                                item.progress != "-1"
                                  ? "display:block;"
                                  : ""
                            },
                            [
                              _c("i", {
                                staticClass: "ico_sty iconfont icon-fabu",
                                on: {
                                  click: function($event) {
                                    return _vm.getDycode(item.id, "mt")
                                  }
                                }
                              })
                            ]
                          ),
                          _c("span", { staticClass: "video_duration" }, [
                            _vm._v(_vm._s(item.duration))
                          ])
                        ]),
                        _c("div", { staticClass: "material_title" }, [
                          item.download_if
                            ? _c("span", [_vm._v("已分享")])
                            : _vm._e(),
                          _vm._v(_vm._s(item.works_name) + " ")
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
        ],
        2
      ),
      !_vm.listLoading
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page,
              layout: "prev, pager, next",
              "page-size": _vm.page_num,
              total: _vm.dataTotal,
              "hide-on-single-page": true,
              "pager-count": 5
            },
            on: { "current-change": _vm.getData }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }