<template>
<!-- 作品库 -->
  <div class="work_library materials common_page">
    <van-tabs v-model="activeName" @change="handleClick" background="#161626" :line-width="64" :line-height="4">
        <van-tab title="日常发布" name="1" title-class="vertical_divider"></van-tab>
        <van-tab title="公共作品" name="2" :disabled="listLoading"></van-tab>
    </van-tabs>
    <div class="filter">
      <div class="title">
        <span class="f12">共{{ dataTotal }}个素材</span>
      </div>
      <div class="right" @click="showFilter=!showFilter">
        <span>筛选</span>
        <van-icon name="filter-o" />
      </div>
    </div>

    <van-overlay :show="showFilter" @click="showFilter = false" :z-index="120">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-field
            readonly
            clickable
            name="picker"
            :value="cityValue"
            label="城市："
            placeholder="请选择"
            @click="cityShow = true"
            is-link
            input-align="right"
          />
          <van-popup v-model="cityShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="cityList"
              @confirm="onCityConfirm"
              @cancel="cityShow = false"
            />
          </van-popup>
          <div class="filter_btn">
            <van-button size="small" class="reset_btn mr20" block @click="resetFilter">重置</van-button>
            <van-button size="small" type="primary" block @click="filters">筛&emsp;选</van-button>
          </div>
        </div>
      </div>
    </van-overlay>

    <div class="list-warp mobile_warp" ref="contentWarp" v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.1)" element-loading-text="素材加载中...">
      <noResult style="height:55vh;" v-if="!listData.length && !listLoading" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无相关素材"></noResult>
      <template v-else>
        <div class="material_list">
          <div class="material_item" v-for="(item) in listData" :key="item.id">
            <div class="item_top">
              <div class="videoSize" @click="videoClick(item)">
                <div class="play_video" v-show='item.showImg'>
                  <i class="iconfont icon-bofang1"></i>
                </div>
                <img :src="item.cover" alt="" v-show='item.showImg'/>
                <div :id="'video' + item.id" v-show='!item.showImg' :style="item.showImg ? 'width:0;height:0':''"></div>
              </div>
              <div class="btns" :style="(item.progress != 0 && item.progress <= 0.99 && item.progress != '-1') ? 'display:block;' : ''">
                <i class="ico_sty iconfont icon-fabu" @click="getDycode(item.id,'mt')"></i>
              </div>
              <span class="video_duration">{{ item.duration }}</span>
            </div>
            <div class="material_title">
              <span v-if="item.download_if">已分享</span>{{item.works_name}}
            </div>
          </div>
        </div>
      </template>
    </div>
    <el-pagination
      background
      :current-page="page"
      v-if="!listLoading"
      @current-change="getData"
      layout="prev, pager, next"
      :page-size="page_num"
      :total="dataTotal"
      :hide-on-single-page="true"
      :pager-count="5"
    >
    </el-pagination>
  </div>
</template>
<script>
import noResult from "../component/noResult.vue";
import HlsJsPlayer from "xgplayer-hls.js";

export default {
  components: {
    noResult,
  },
  data () {
    return {
      car_type: [],
      tagType: '2',
      content_type: '',
      keyword: '',
      page: 1,
      contentList: [],
      listData: [],
      listLoading: false,
      dataTotal: 0,
      filterChecked: false, // 是否只看已下载作品,1为是，默认为0
      player: '',
      downList: [],
      dialogVisible: false,
      activeName: '1', // 1,日常发布，2公共作品
      showFilter: false,
      cityShow: false,
      cityValue: '',
      cityList: [],
      filterData: ''
    }
  },
  computed: {
    page_num () {
        return 10
    },
    type () {
      return this.$route.path == '/work_library_sz' ? 'sz' : 'am'
    }
  },
  watch: {
    // showFilter(v) {
    //   if(!v){
    //     console
    //   }
    // }
  },
  created () {
    this.getData(1,true)
    this.getCity()
  },
  mounted () {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://lf3-static.bytednsdoc.com/obj/eden-cn/fljpeh7hozbf/douyin_open/cdn/dy_open_util_v0.0.6.umd.js';
      document.body.appendChild(script);
  },
  methods: {
    // 作品类型切换
    handleClick(tab, event) {
      this.showFilter = false
      this.resetFilter()
      this.getData(1,true)
    },

    onCityConfirm (value) {
      this.cityValue = value
      this.cityShow = false
    },
    resetFilter () {
      this.cityValue = ''
    },
    filters () {
      this.showFilter = false
      this.getData(1,true)
    },

    getData (page,ifFilter) {
      this.dialogVisible = false
      this.page = page
      this.listLoading = true
      this.listData = []
      this.player ? this.player.destroy() : ''
      this.player = ''

      if(ifFilter){
        this.filterData = this.cityValue ? JSON.parse(JSON.stringify(this.cityValue)) : ''
      }else{
        this.cityValue = this.filterData
      }

      let data = {
        works_type: this.activeName, // 1: 日常发布，公共作品
        page: this.page, // 页码，默认为1
        page_num: this.page_num, // 每页条数，默认为40
        email_domain: this.type== 'sz' ? 'zuche.com' : '',
        city:  this.filterData
      }

      this.$http({
        url: "/release/douyin_works_list",
        method: "POST",
        data: this.qs.stringify(data),
      }).then((res) => {
        this.listLoading = false
        this.dataTotal = res.data.total_num
        if (res.data.status == 0) {

          res.data.works_list.forEach(ele => {
            ele.showImg = true
            ele.progress = 0
          });

          this.listData = res.data.works_list
          this.$refs.contentWarp.scrollTop = 0
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch((error) => console.log(error));
    },

    // 计算tooltip位置
    filterNum (n) {
      if (n % 5 == 1 || n % 5 == 2 || n % 5 == 3 ) {
        return true
      }else{
        return false
      }
    },

    // 点击事件
    videoClick(item){
      // console.log(11)
      this.listData.forEach(ele => {
        ele.showImg = true
      });
      item.showImg = false
      this.player ? this.player.destroy() : ''
      this.player = ''
      this.$nextTick(()=>{
        let player = new HlsJsPlayer({
          id: `video${item.id}`,
          url: item.m3u8_path,
          loop: true,
          volume: 0.3,
          // autoplayMuted: true,
          ignores: ["fullscreen","play", "time", "progress"],
          controlsList: ['nodownload'],
          autoplay: true, // 自动播放
          'x5-playsinline': true,
          playsinline: true,
          cors: true,
          'x5-video-player-type': 'h5',
          // playsinline: false,
          // useHls: true,
          // hlsOpts: {
          //  xhrSetup: function(xhr, url) {
          //    xhr.withCredentials = true;
          //  }
          // }, //hls.js可选配置项
          'x5-video-player-fullscreen': false,
          // 'x5-video-player-type': 'h5',
          // playsinline: true,
          width: "100%",
          height: '100%'
        });
        this.player = player
        // console.log(this.player)
        // this.player.emit('play')
      })
    },

    publishH5(res){
      console.log(encodeURIComponent(res.video_path))
       const link = document.createElement('a');
      // link.target = '_blank'  //如果该值为‘_self'该行可忽略
      link.style.display = 'none';
      link.href = 'snssdk1128://openplatform/share?share_type=h5&client_key=' + res.client_key + '&nonce_str='+ res.nonce_str +'&share_to_publish='+
      res.share_to_publish +'&signature='+ res.signature +'&state='+ res.state + '&timestamp='+ res.timestamp +'&title=' + encodeURIComponent(res.title) +'&state='+ res.state + '&video_path='+ encodeURIComponent(res.video_path)
      document.body.appendChild(link);
      link.click();
      link.remove();
      // const schema = window.dy_open_util.serialize({
      //   share_type: "h5",
      //   client_key: res.client_key,
      //   nonce_str: res.nonce_str,
      //   share_to_publish: res.share_to_publish,
      //   signature: res.signature,
      //   state: res.state,
      //   timestamp: res.timestamp + '',
      //   title: res.title,
      //   video_path: res.video_path
      // });
    },

    getDycode(id,type){
      let formData = new FormData()
      formData.append('works_id',id)
      formData.append('share_type',type)
      this.type== 'sz' ? formData.append('email_domain','zuche.com') : formData.append('email_domain','')

      this.$http({
        url: "/release/douyin_released_au/",
        method: "POST",
        data: formData
      }).then((res) => {
        if (res.data.status == 0) {
            this.publishH5(res.data.res)
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch((error) => console.log(error));
    },
    getCity () {
      this.$http({
      url: "/works/get_city/",
      method: "GET"
    }).then((res) => {
      if (res.data.status == 0) {
        res.data.city_list.forEach((item)=>{
          this.cityList = [...Object.values(item)]
        })
      }
    }).catch((error) => console.log(error));
  }


  },
  destroyed (){
    this.player ? this.player.destroy() : ''
  }
}
</script>
<style lang="scss" scoped>
  .work_library{
    .list-warp{
      padding-bottom: 10px;
      .material_title{
        cursor: initial;
        line-height: 22px;
        &:hover{
          color: inherit;
        }
        span{
          font-size: 12px;
          display: inline-block;
          border-radius: 4px;
          color: $white8;
          background: #409eff;
          padding: 3px 5px;
          margin-right: 10px;
          transform: scale(0.8);
        }
      }
    }

    .mobile_warp{
      height: 74vh;
      overflow-x: hidden;
      @include scrollbar(3px,5px);
      .material_list{
          grid-template-columns: 100%;
          .material_item{
            .videoSize{
                height: 24vh;
            }
            .btns{
              display: flex;
            }
          }
      }
      .xgplayer-skin-skinName{
        height: 100% !important;
      }
    }
    .play_video{
      width: 70px;
      height: 70px;
      background: rgba(0,0,0,0.38);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 115;
      border-radius: 50%;
      margin: -35px auto auto -35px;
      @include flex-align-justify(center,center);
      i{
        font-size: 30px;
        color: rgba(255,255,255,0.7);
      }
      &:hover{
        opacity: 0.85;
      }
    }
  }

  /deep/.van-tab--active{
    font-weight: bold;
  }
  .van-tabs{
    position: relative;
    z-index: 200;
  }
  .filter{
    padding: 13px 30px;
    @include flex-align-justify(center,space-between);
    background-color: #161626;
    position: relative;
    z-index: 200;
    .title{
      color: $white6;
    }
    .right{
      color: $white9;
      span{
        margin-right: 4px;
      }
    }

  }
  .wrapper {
    margin-top: 86px;
  }

  .block {
    width: 100%;
    min-height: 150px;
    background-color: #161626;
  }
</style>
